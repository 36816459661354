<li class="app-faculties-selection-list-item">
  <div class="faculty">
    <mat-checkbox
      class="checkbox"
      [checked]="!!control"
      [disabled]="control?.disabled || disabled"
      (change)="toggle.emit()"
      >{{ option.label }}</mat-checkbox
    >
    <div class="actions">
      <button
        mat-icon-button
        matTooltip="Agregar restricción"
        matTooltipPosition="right"
        [disabled]="!control || disabled"
        (click)="addRestriction.emit()"
      >
        <mat-icon>note_add</mat-icon>
      </button>
    </div>
  </div>
  @if (control?.value?.restriction?.length) {
    <ul class="restriction-list">
      @for (
        restriction of control.controls.restriction.controls;
        track $index
      ) {
        <li class="restriction-item">
          <app-study-form-input
            [control]="restriction.controls.unit"
            [options]="restrictionUnitOptions"
            (undo)="
              resetRestriction.emit({ field: 'unit', restrictionIndex: $index })
            "
            label="Unidad"
            type="select"
          />
          <app-study-form-input
            class="value"
            label="Monto"
            type="number"
            [control]="restriction.controls.value"
            (undo)="
              resetRestriction.emit({
                field: 'value',
                restrictionIndex: $index
              })
            "
          />
          <button
            mat-icon-button
            title="Eliminar restricción"
            (click)="removeRestriction.emit($index)"
            [disabled]="disabled"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </li>
      }
    </ul>
  }
</li>
