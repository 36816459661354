export const RESTRICTION_UNITS = [
  {
    value: 'CLP',
    displayName: 'Pesos Chilenos',
  },
  {
    value: 'UF',
    displayName: 'Unidades de Fomento',
  },
];
