import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { environment } from '../../environments/environment';
import { Customer, CustomerFaculty, CustomerLawFirm } from '../types';
import { createErrorResponse, createErrorPaginatedResponse } from './utils';
import { SimpleResponse, PaginatedResponse } from './types';
import { UNKNOWN_HTTP_ERROR } from './constants';

const CUSTOMERS_URL = `${environment.api}/v2/customers/`;

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  public async getCustomers(): Promise<PaginatedResponse<Customer[]>> {
    try {
      const resultado = await lastValueFrom(
        this.http.get<PaginatedResponse<Customer[]>>(CUSTOMERS_URL),
      );

      return resultado;
    } catch {
      return createErrorPaginatedResponse(UNKNOWN_HTTP_ERROR);
    }
  }

  public async getCustomerFaculties(
    customerId: number,
  ): Promise<SimpleResponse<CustomerFaculty[]>> {
    const urlFaculties = `${CUSTOMERS_URL}${customerId}/faculties/`;

    try {
      const faculties = await lastValueFrom(
        this.http.get<CustomerFaculty[]>(urlFaculties),
      );

      return {
        data: faculties,
      };
    } catch {
      return createErrorResponse(UNKNOWN_HTTP_ERROR);
    }
  }

  public async getCustomerLawFirms(
    customerId: string,
  ): Promise<SimpleResponse<CustomerLawFirm[]>> {
    const urlLawFirms = `${CUSTOMERS_URL}${customerId}/law-firms/`;

    try {
      const lawFirms = await lastValueFrom(
        this.http.get<CustomerLawFirm[]>(urlLawFirms),
      );

      return {
        data: lawFirms,
      };
    } catch {
      return createErrorResponse(UNKNOWN_HTTP_ERROR);
    }
  }
}
