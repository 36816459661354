import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon, MatIconModule } from '@angular/material/icon';

import { ButtonModule } from '../button/button.module';
import { InputGroupModule } from '../input-group/input-group.module';
import { ListBoxModule } from '../list-box/list-box.module';
import { StudyFormInputModule } from '../study-form-input/study-form-input.module';
import { StudySectionModule } from '../study-section/study-section.module';

import { FacultiesSelectionListComponent } from './sections/study-administration-section/faculties-selection-list/faculties-selection-list.component';
import { FacultiesSelectionListItemComponent } from './sections/study-administration-section/faculties-selection-list/faculties-selection-list-item/faculties-selection-list-item.component';
import { StudyAdministrationSectionComponent } from './sections/study-administration-section/study-administration-section.component';
import { StudyDurationSectionComponent } from './sections/study-duration-section/study-duration-section.component';
import { StudyFormComponent } from './study-form.component';
import { StudyMainSectionComponent } from './sections/study-main-section/study-main-section.component';
import { StudyShareholdersSectionComponent } from './sections/study-shareholders-section/study-shareholders-section.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    FacultiesSelectionListComponent,
    FacultiesSelectionListItemComponent,
    StudyAdministrationSectionComponent,
    StudyDurationSectionComponent,
    StudyFormComponent,
    StudyMainSectionComponent,
    StudyShareholdersSectionComponent,
  ],
  imports: [
    ButtonModule,
    CommonModule,
    InputGroupModule,
    ListBoxModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIcon,
    MatIconModule,
    ReactiveFormsModule,
    RouterModule,
    StudyFormInputModule,
    StudySectionModule,
  ],
  exports: [StudyFormComponent],
})
export class StudyFormModule {}
