import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  FormArray,
} from '@angular/forms';

const errorMessage = (numberOfAdministrators: number) => {
  if (numberOfAdministrators === 1) {
    return 'El número de firmas debe ser 1';
  }
  return `El número de firmas debe estar entre 1 y ${numberOfAdministrators}`;
};

export const SignatureValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const numberOfSignaturesControl = control.get('numberOfSignatures');
  const numberOfSignatures = numberOfSignaturesControl?.value;
  const administrationArray = control.get('administration') as FormArray;
  const numberOfAdministrators = administrationArray?.length;

  if (numberOfAdministrators > 0) {
    if (numberOfSignatures < 1 || numberOfSignatures > numberOfAdministrators) {
      numberOfSignaturesControl.setErrors({
        message: errorMessage(numberOfAdministrators),
      });

      return { numberOfSignatures: true };
    }
  }

  return null;
};
