<app-study-section
  [initiallyExpanded]="true"
  [isInvalid]="studyForm.invalid"
  [isUnsaved]="studyForm.dirty"
  class="app-study-administration-section"
  title="Administración"
>
  <app-input-group
    (add)="addGroup()"
    [formArray]="groups"
    title="Grupos de Administración"
  >
    @for (group of groups.controls; track groupIndex; let groupIndex = $index) {
      <app-study-section
        [initiallyExpanded]="true"
        [isInvalid]="group.invalid"
        [isUnsaved]="group.dirty"
        [title]="'Grupo de administración ' + (groupIndex + 1)"
      >
        <app-study-form-input
          [control]="group.controls.numberOfSignatures"
          [errorMessage]="group.controls.numberOfSignatures.errors?.['message']"
          (search)="searchField('administration')"
          (undo)="resetGroupField('numberOfSignatures', groupIndex)"
          label="Número de firmas"
          type="number"
        />
        <app-input-group
          title="Administradores"
          [formArray]="group.controls.administration"
          (add)="addAdministrator(group)"
        >
          @for (
            administrator of group.controls.administration.controls;
            track administratorIndex;
            let administratorIndex = $index
          ) {
            <app-study-section
              [title]="administrator.controls.name.value"
              [initiallyExpanded]="administrator.invalid"
              [isUnsaved]="administrator.dirty"
              [isInvalid]="administrator.invalid"
            >
              <app-study-form-input
                [control]="administrator.controls.name"
                (search)="searchField('administration')"
                (undo)="
                  resetGroupAdministratorField(
                    'name',
                    groupIndex,
                    administratorIndex
                  )
                "
                label="Nombre"
              />
              <app-study-form-input
                appRutMask
                [control]="administrator.controls.rut"
                (search)="searchField('administration')"
                (undo)="
                  resetGroupAdministratorField(
                    'rut',
                    groupIndex,
                    administratorIndex
                  )
                "
                label="RUT"
              />
              <app-study-form-input
                [control]="administrator.controls.administrationRole"
                (search)="searchField('administration')"
                (undo)="
                  resetGroupAdministratorField(
                    'administrationRole',
                    groupIndex,
                    administratorIndex
                  )
                "
                label="Forma individual de administrar"
                type="select"
                [options]="administrationRoleOptions"
              />
              <app-button
                color="secondary"
                [disabled]="disabled"
                (buttonClick)="removeAdministrator(group, administratorIndex)"
              >
                Eliminar
              </app-button>
            </app-study-section>
          }
        </app-input-group>

        <app-faculties-selection-list
          [control]="group.controls.faculties"
          [facultyOptions]="customerFacultyOptions"
          (addFacultyRestriction)="addRestriction($event, groupIndex)"
          (removeFacultyRestriction)="
            removeFacultyRestriction($event, groupIndex)
          "
          (resetFacultyRestriction)="
            resetFacultyRestriction($event, groupIndex)
          "
        >
          <button mat-icon-button (click)="searchField('faculties')">
            <mat-icon>search</mat-icon>
          </button></app-faculties-selection-list
        >
        <app-button
          color="secondary"
          [disabled]="disabled || groups.length < 2"
          (buttonClick)="removeGroup(groupIndex)"
        >
          Eliminar Grupo
        </app-button>
      </app-study-section>
    }
  </app-input-group>
</app-study-section>
