import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FacultyItem } from '../../types';
import { FacultyOption } from '../types';
import { RESTRICTION_UNITS } from '../../../../../../constants/restriction';
import { RestrictionReset } from './types';

@Component({
  selector: 'app-faculties-selection-list-item',
  templateUrl: './faculties-selection-list-item.component.html',
  styleUrl: './faculties-selection-list-item.component.scss',
})
export class FacultiesSelectionListItemComponent {
  @Input() control: FacultyItem;
  @Input() option: FacultyOption;
  @Input() disabled: boolean;
  @Output() toggle = new EventEmitter();
  @Output() addRestriction = new EventEmitter();
  @Output() removeRestriction = new EventEmitter<number>();
  @Output() resetRestriction = new EventEmitter<RestrictionReset>();

  restrictionUnitOptions = RESTRICTION_UNITS.map(({ displayName, value }) => ({
    label: displayName,
    value,
  }));
}
