import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';

import {
  AdministrationFormGroup,
  AdministrationItem,
  FacultyItem,
  FacultyRestrictionAdd,
  FacultyRestrictionItem,
  FacultyRestrictionRemove,
  FacultyRestrictionReset,
} from './types';
import {
  AdministrationGroup,
  AdministrationGroupAdministrator,
  AdministrationGroupFaculty,
  AdministrationGroupFacultyRestriction,
} from '../../../../types';
import { CustomerService } from '../../../../services/customer.service';
import { rutValidator } from '../../../../utils/form-validators';
import {
  ADMINISTRATION_KINDS,
  ADMINISTRATION_ROLES,
} from '../../../../constants/administration-kinds';
import { DEFAULT_RESTRICTION_UNIT } from './faculties-selection-list/faculties-selection-list-item/constants';
import { SignatureValidator } from './validators/signature-validator';
import { StudyBaseSectionComponent } from '../study-base-section.component';

@Component({
  selector: 'app-study-administration-section',
  templateUrl: './study-administration-section.component.html',
  styleUrls: ['./study-administration-section.component.scss'],
})
export class StudyAdministrationSectionComponent
  extends StudyBaseSectionComponent
  implements OnInit
{
  override studyForm = new FormGroup({
    groups: new FormArray<AdministrationFormGroup>([], Validators.required),
  });

  administrationKindOptions = ADMINISTRATION_KINDS.map(
    ({ displayName, value }) => ({
      label: displayName,
      value,
    }),
  );
  administrationRoleOptions = ADMINISTRATION_ROLES.map(
    ({ displayName, value }) => ({
      label: displayName,
      value,
    }),
  );

  customerFacultyOptions = [];

  constructor(private customerService: CustomerService) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.loadCustomerFaculties();
  }

  async loadCustomerFaculties() {
    const { data } = await this.customerService.getCustomerFaculties(
      this.study.customerId,
    );

    this.customerFacultyOptions = data.map((faculty) => ({
      label: faculty.customName,
      value: faculty.externalId,
    }));
  }

  get studyGroups() {
    return this.study?.groups || [];
  }

  get groups() {
    return this.studyForm.get('groups') as FormArray<AdministrationFormGroup>;
  }

  createGroup(group?: AdministrationGroup): AdministrationFormGroup {
    return new FormGroup(
      {
        numberOfSignatures: new FormControl(group?.numberOfSignatures || 0, [
          Validators.required,
          Validators.min(0),
        ]),
        administration: new FormArray(
          group?.administration.map((administrator) =>
            this.createAdministrator(administrator),
          ) || [],
          Validators.required,
        ),
        faculties: new FormArray(
          group?.faculties.map((faculty) => this.createFaculty(faculty)) || [],
          Validators.required,
        ),
      },
      { validators: SignatureValidator },
    );
  }

  createFaculty(faculty: AdministrationGroupFaculty): FacultyItem {
    return new FormGroup({
      facultyId: new FormControl(
        faculty?.facultyId || null,
        Validators.required,
      ),
      message: new FormControl(faculty?.message || null),
      restriction: new FormArray(
        faculty?.restriction.map((restriction) =>
          this.createRestriction(restriction),
        ) || [],
      ),
    });
  }

  createRestriction(
    restriction?: AdministrationGroupFacultyRestriction,
  ): FacultyRestrictionItem {
    return new FormGroup({
      value: new FormControl(restriction?.value || null, [
        Validators.required,
        Validators.min(0),
      ]),
      unit: new FormControl(
        restriction?.unit || DEFAULT_RESTRICTION_UNIT,
        Validators.required,
      ),
    });
  }

  addRestriction({ facultyIndex }: FacultyRestrictionAdd, groupIndex: number) {
    const newRestriction = this.createRestriction();
    const facultyId = this.customerFacultyOptions[facultyIndex].value;
    const group = this.groups.controls[groupIndex];
    const facultyControlIndex = group.controls.faculties.controls.findIndex(
      (faculty) => faculty.value.facultyId === facultyId,
    );
    const faculty = group.controls.faculties.controls[facultyControlIndex];

    const restrictions = faculty.get(
      'restriction',
    ) as FormArray<FacultyRestrictionItem>;
    restrictions.markAsDirty();
    faculty.markAsDirty();

    restrictions.push(newRestriction);
  }

  removeFacultyRestriction(
    { facultyIndex, restrictionIndex }: FacultyRestrictionRemove,
    groupIndex: number,
  ) {
    const facultyId = this.customerFacultyOptions[facultyIndex].value;
    const group = this.groups.controls[groupIndex];
    const facultyControlIndex = group.controls.faculties.controls.findIndex(
      (faculty) => faculty.value.facultyId === facultyId,
    );
    const faculty = group.controls.faculties.controls[facultyControlIndex];

    faculty.markAsDirty();
    faculty.controls.restriction.removeAt(restrictionIndex);
  }

  addGroup() {
    const newGroup = this.createGroup();

    newGroup.markAsDirty();
    this.groups.push(newGroup);
  }

  removeGroup(index: number) {
    this.studyForm.markAsDirty();
    this.groups.removeAt(index);
  }

  createAdministrator(
    administrator: AdministrationGroupAdministrator,
  ): AdministrationItem {
    return new FormGroup({
      administrationRole: new FormControl(
        administrator?.administrationRole || null,
        Validators.required,
      ),
      name: new FormControl(administrator?.name || null, Validators.required),
      rut: new FormControl(administrator?.rut || null, [
        Validators.required,
        rutValidator,
      ]),
    });
  }

  addAdministrator(group: AdministrationFormGroup) {
    const adminFormGroup = this.createAdministrator(null);

    group.controls.administration.push(adminFormGroup);
    adminFormGroup.markAsDirty();
  }

  removeAdministrator(group: AdministrationFormGroup, index: number) {
    group.controls.administration.markAsDirty();
    group.controls.administration.removeAt(index);
  }

  override syncFormWithStudy({ previousStudy, currentStudy }) {
    if (previousStudy?.groups.length !== currentStudy?.groups.length) {
      const isDisabled = this.groups.disabled;

      this.groups.clear({ emitEvent: false });
      this.studyGroups.forEach((group) => {
        this.groups.push(this.createGroup(group));
      });

      isDisabled && this.groups.disable({ emitEvent: false });
    }

    this.studyForm.patchValue(
      {
        groups: this.studyGroups,
      },
      { emitEvent: false },
    );
  }

  updateChildrenPristineStates(
    formControl: FormArray<FormGroup> | FormGroup,
    studyLeaveData: unknown = {},
  ) {
    if (formControl instanceof FormArray) {
      if (
        Array.isArray(studyLeaveData) &&
        formControl.value.length === 0 &&
        studyLeaveData.length === 0 &&
        formControl.dirty
      ) {
        formControl.markAsPristine();

        return;
      }

      for (const [index, control] of formControl.controls.entries()) {
        this.updateChildrenPristineStates(
          control,
          studyLeaveData?.[index] || {},
        );
      }

      const someDirty = formControl.controls.some((control) => control.dirty);

      if (
        !someDirty &&
        formControl.length === (studyLeaveData as unknown[]).length &&
        formControl.dirty
      ) {
        formControl.markAsPristine();
      }

      return;
    }

    for (const key of Object.keys(formControl.controls)) {
      const control = formControl.get(key);

      if (control instanceof FormArray || control instanceof FormGroup) {
        this.updateChildrenPristineStates(control, studyLeaveData[key]);

        continue;
      }

      if (control.dirty && control.value === studyLeaveData[key]) {
        control.markAsPristine();
      }
    }
  }

  override updatePristineStates() {
    this.updateChildrenPristineStates(this.studyForm, this.study);
  }

  resetGroupField(field: string, index: number) {
    const studyGroup = this.studyGroups[index];

    this.groups.controls[index].get(field).reset(studyGroup?.[field]);
  }

  resetGroupAdministratorField(
    field: string,
    groupIndex: number,
    administratorIndex: number,
  ) {
    const studyGroup = this.studyGroups[groupIndex];
    const studyGroupAdministrator =
      studyGroup.administration[administratorIndex];
    const groupAdministrations = this.groups.controls[groupIndex].get(
      'administration',
    ) as FormArray;
    const administration = groupAdministrations.controls[administratorIndex];

    administration.get(field).reset(studyGroupAdministrator?.[field]);
  }

  resetFacultyRestriction(
    { facultyIndex, restrictionIndex, field }: FacultyRestrictionReset,
    groupIndex: number,
  ) {
    const facultyId = this.customerFacultyOptions[facultyIndex].value;

    const studyGroup = this.studyGroups[groupIndex];
    const studyGroupFacultyIndex = studyGroup.faculties.findIndex(
      (faculty) => faculty.facultyId === facultyId,
    );
    const studyGroupFaculty =
      studyGroupFacultyIndex > -1
        ? studyGroup.faculties[studyGroupFacultyIndex]
        : undefined;
    const studyGroupFacultyRestriction =
      studyGroupFaculty?.restriction[restrictionIndex];

    const group = this.groups.controls[groupIndex];
    const facultyControlIndex = group.controls.faculties.controls.findIndex(
      (faculty) => faculty.value.facultyId === facultyId,
    );
    const faculty = group.controls.faculties.controls[facultyControlIndex];
    const restriction = faculty.controls.restriction.controls[restrictionIndex];

    if (field === 'unit' && !studyGroupFacultyRestriction) {
      restriction.get(field).setValue(DEFAULT_RESTRICTION_UNIT);
      return;
    }

    restriction.get(field).reset(studyGroupFacultyRestriction?.[field]);

    if (
      studyGroupFaculty?.restriction.length !==
      faculty.controls.restriction.length
    ) {
      this.groups.controls[groupIndex].controls.faculties.markAsDirty();
    }
  }
}
